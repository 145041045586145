<template>
  <van-overlay
      :show="loadingOfWorkorderDetails || loadingOfCustomerDetails || loadingOfCustomerDepartmentDetails ||loadingOfHandelDetails"
      @click="show = false">
    <div class="wrapper" @click.stop>
      <van-loading type="spinner" color="#1989fa"/>
    </div>
  </van-overlay>
  <div>
    <van-nav-bar fixed border left-arrow
                 title="工单详情页"
                 left-text="返回"
                 @click-left="router.back"/>

    <div class="page-container">
      <div class="feedback" v-if="workorder.workorder.progress === 17">
        <van-button type="primary" size="small" @click="feedback" v-if="workorder.workorder.feedbackScore === 0">回访
        </van-button>
        <van-tag color="#7232dd" plain size="large" style="margin-right: 20px;" v-else>已回访</van-tag>
      </div>
      <van-cell-group inset title="工单信息">
        <van-field label="预约时间" readonly :model-value="workorder.workorder.deadLine"/>
        <van-field label="工单描述" readonly type="textarea" :model-value="workorder.workorder.description"/>
      </van-cell-group>

      <van-cell-group inset title="工程师信息">
        <van-field label="公司" readonly :model-value="workorder.handle.departmentName"/>
        <van-field label="姓名" readonly :model-value="workorder.handle.name"/>
        <van-field label="电话" readonly :model-value="workorder.handle.cellphone"/>
        <van-divider content-position="left" v-if="showMap">工程师位置信息</van-divider>
        <smp-map v-if="showMap" :markers="engineerMaker" :map-props="mapProps"/>
      </van-cell-group>

      <van-cell-group inset title="客户信息">
        <van-field label="客户姓名" readonly :model-value="workorder.customer.name"/>
        <van-field label="客户电话" readonly :model-value="workorder.customer.cellphone"/>
        <van-field label="客户单位" readonly :model-value="workorder.customerDepartment.name"/>
        <van-field label="客户地址" readonly :model-value="workorder.customer.address"/>
        <van-field label="预约时间" readonly :model-value="workorder.workorder.appointmentTime"/>
      </van-cell-group>

      <SmpFileCellGroup :files="workorder.workorder.files"/>

      <van-cell-group inset title="工单日志">
        <van-steps direction="vertical" :active="logsTotal">
          <van-step v-for="item in workorder.workorder.logs" :key="item.id">
            <small>{{ item.createTime }} {{ item.log }}</small>
          </van-step>
        </van-steps>
      </van-cell-group>

    </div>
  </div>
</template>

<script setup>
//TODO 工单详情页
import {useRouter} from "vue-router";
import {onMounted, reactive, ref} from "vue";
import {Toast} from "vant";

import {requestWorkorder} from "@/api/workorder";
import {queryCustomer, queryCustomerDepartment} from "@/api/customer";

import {nameEncryption, phoneEncryption} from "../../../plugin/encryption";

import SmpFileCellGroup from "../../../component/SmpFileCellGroup";
import SmpMap from "../../../component/SmpMap";
import {queryHandle, requestAccountLocation} from "../../../api/user";
import {queryWorkorderByCode} from "../../../api/workorder";
import {getSearchParams} from "../../../plugin/tools";
const router = useRouter();

const query = getUrlParam();
const workorder = reactive({workorder: {}, customer: {}, customerDepartment: {}, handle: {}});
const logsTotal = ref("");
const loadingOfWorkorderDetails = ref(false);
const loadingOfCustomerDetails = ref(false);
const loadingOfCustomerDepartmentDetails = ref(false);
const loadingOfHandelDetails = ref(false);
const showMap = ref(false)
const engineerMaker = ref([])
const mapProps = ref({
  viewMode: "3D",
  terrain: true,
  zoom: 13,
  zoomEnable: false,
  dragEnable: false,
  resizeEnable: false,
  center:[]
})

/**
 * 获取好的信息
 *
 */
const requestWorkorderInfo = () => {
  loadingOfWorkorderDetails.value = true;
  requestWorkorder(query.workorderId).then(res => {
    workorder.workorder = res.data;
    logsTotal.value = res.data.logs.length - 1;
    loadingOfWorkorderDetails.value = false;
    requestCustomer(res.data.clientId);
    requestCustomerDepartment(res.data.clientDepartmentId);
    requestHandelInfo(res.data.handlerId);
  }).catch(err => {
    loadingOfWorkorderDetails.value = false;
    console.error("request workorder info failed", err);
    if (err.status === 40400) {
      Toast.fail({
        message: "暂无查询到此工单的信息",
        forbidClick: true
      });
    }
  });
};

/**
 * 获取客户信息
 * @param id
 */
const requestCustomer = (id) => {
  loadingOfCustomerDetails.value = true;
  queryCustomer(id).then(res => {
    res.data.name = nameEncryption(res.data.name);
    res.data.cellphone = phoneEncryption(res.data.cellphone);
    workorder.customer = res.data;
    loadingOfCustomerDetails.value = false;
  }).catch(err => {
    loadingOfCustomerDetails.value = false;
    console.error("Request customer failed", err);
  });
};

/**
 * 获取客户单位信息
 * @param id
 */
const requestCustomerDepartment = (id) => {
  loadingOfCustomerDepartmentDetails.value = true;
  queryCustomerDepartment(id).then(res => {
    workorder.customerDepartment = res.data;
    loadingOfCustomerDepartmentDetails.value = false;
  }).catch(err => {
    loadingOfCustomerDepartmentDetails.value = false;
    console.error("Request customer department failed", err);
  });
};

/**
 * 获取处理人信息
 * @param id
 */
const requestHandelInfo = (id) => {
  loadingOfHandelDetails.value = true;
  queryHandle(id).then(res => {
    workorder.handle = res.data;
    loadingOfHandelDetails.value = false;
    requestEngineerLoLa(res.data.id)
  }).catch(err => {
    loadingOfHandelDetails.value = false;
    console.error("Request handle info failed", err);
  });
};

/**
 * 获取工程师经纬度信息
 */
const requestEngineerLoLa = (id) => {
  requestAccountLocation(id).then(res => {
    if (res.data?.latitude && res.data?.longitude) {
      showMap.value = true
      mapProps.value.center = [res.data.longitude, res.data.latitude]
      engineerMaker.value = [new AMap.Marker({position: new AMap.LngLat(res.data.longitude, res.data.latitude)})]
    } else {
      showMap.value = false
      engineerMaker.value = []
    }
  }).catch(err => {
    engineerMaker.value = [];
    showMap.value = false;
    console.warn("获取工程师信息失败", err)
  })
}

const feedback = () => {
  router.push({path: "/feedback", query});
};


const getWorkorderByCode = () => {
  let { id,workorderId } = query
 if (!workorderId){
   queryWorkorderByCode({key:id}).then( res => {
     console.log(res.status === 20000)
     if(res.status === 20000){
       let origin = window.location.origin
       let pathname = window.location.pathname;
       let urlParams = res.data.split("?");
       let url = origin + pathname + "?" + urlParams[1]
       window.location.href = url
     }else{
     }
   })
 }
}


onMounted(() => {
  validationUrlParam();
  const workorderId = getUrlParam("workorderId");

  if (!workorderId) {
    // router.back();
  }
  getWorkorderByCode() // 通过工单编号获取工单信息
  requestWorkorderInfo();
});
</script>

<style scoped>
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.feedback {
  width: 100%;
  text-align: right;
}

.feedback button {
  margin-right: 20px;
  padding: 15px;
}
</style>
